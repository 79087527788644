import React from 'react';
import styled from 'styled-components';
import Vimeo from '@components/vimeo';
import Image from '@components/image';

const PowerContainer = styled.section`
  width: ${({ columns }) => columns};
  margin-bottom: 5px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
const Container = styled.article`
  width: 100%;
  height: 100%;
`;

export default function GalleryItem({
  image,
  type,
  vimeoId,
  video,
  width,
  items,
  columns = 1,
}) {
  const getContainerWidth = () => {
    if (columns == 3) {
      return '33.333333333333%';
    }
    if (columns == 2) {
      return '50%';
    }
    if (columns == 1) {
      return '100%';
    }
  };

  console.log(type);
  return (
    <PowerContainer columns={getContainerWidth()}>
      <Container columns={getContainerWidth()}>
        {video?.vimeoId ? (
          <Vimeo id={video.vimeoId} />
        ) : (
          <Image
            src={image?.file?.url}
            alt="test"
            width={Math.round(940 / columns)}
            gif={image?.file?.contentType === 'image/gif'}
          />
        )}
      </Container>
    </PowerContainer>
  );
}
