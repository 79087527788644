import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  margin-bottom: ${props => (props.gallery ? '0' : '50px')};
  margin-top: 25px;
  margin-bottom: 25px;
`;

const VideoStyles = styled.div`
  padding: ${props => (props.isPortrait ? '80% 0 0 0' : '56.25% 0 0 0')};

  position: relative;
  /* margin-bottom: ${props => (props.gallery ? '0' : '27px')}; */
  // margin-bottom: 50px;

  iframe {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Client = styled.p`
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 27px;
`;

// const Title = styled.p`
//   font-size: 16px;
//   font-weight: var(--font-weight-regular);
//   padding: 10px 0;
// `;

const Title = styled.div`
  font-size: 26px;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  margin-bottom: 25px;
  text-align: center;
  h3 {
    font-weight: var(--font-weight-regular);
    text-transform: none;
    font-size: 20px;
    margin-top: 5px;
  }

  span {
    font-weight: var(--font-weight-regular);
    margin-top: 5px;
  }
`;

export default function Video({ client, id, gallery = true, title, portrait }) {
  const isCadillacFairview = client === 'Cadillac Fairview';

  return (
    <Container gallery={gallery}>
      {gallery && !isCadillacFairview && (
        <Title>
          {client} <span> | {title}</span>
        </Title>
      )}
      {id && (
        <VideoStyles isPortrait={portrait}>
          <iframe
            src={`https://player.vimeo.com/video/${id}`}
            loading="lazy"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title={title}
          ></iframe>
        </VideoStyles>
      )}
    </Container>
  );
}
