import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Layout from '@components/layout';
import Vimeo from '@components/vimeo';
import Wrapper from '@components/wrapper';
import RichText from '@components/richtext';
import Grid from '@components/grid';
import Pagination from '@components/pagination';
import GalleryItem from '@components/galleryitem';
import Image from '@components/image';

const Huh = styled.h1`
  padding-top: 200px;
`;

const AddtionalWorkContainer = styled.section`
  border: solid 1px purple;
  color: red;
`;

const Title = styled.div`
  font-size: 26px;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  /* margin-bottom: 30px; */
  h3 {
    font-weight: var(--font-weight-regular);
    text-transform: none;
    font-size: 20px;
    margin-top: 5px;
  }

  span {
    font-weight: var(--font-weight-regular);
    margin-top: 5px;
  }
`;

const Description = styled.section`
  text-align: center;
  margin-bottom: 50px;
`;

const renderSwitch = item => {
  switch (item.__typename) {
    case 'ContentfulGalleryItem':
      return (
        <GalleryItem
          key={item.id}
          {...item}
          type={item.vimeoId ? 'video' : 'image'}
          width={1000}
        />
      );
    case 'ContentfulGrid':
      return <Grid key={item.id} items={item.items} {...item} />;
    case 'ContentfulText':
      return <RichText key={item.id} document={item.richText.json} />;
    case 'ContentfulVideo':
      return (
        <Vimeo
          id={item.vimeoId}
          title={item.title}
          client={item.client.name}
          gallery={true}
          portrait={item.portrait}
        />
      );
    default:
      return null;
  }
};

export default function Project({
  data,
  pageContext: { prev, next },
  location,
}) {
  const hasPrimaryGif = data.contentfulProject?.video?.gif?.file?.url || null;
  const isHealthProject = !!data.contentfulProject.healthProject;
  const isProject =
    data.contentfulProject.internal.type === 'ContentfulProject';

  const isPortraitVideo = data.contentfulProject?.video?.portrait;

  console.log(data);
  const projectTitle = () =>
    data.contentfulProject.video.client.name === 'Original Content' ? (
      <h2>
        {data.contentfulProject.video.title}
        <span> | {data.contentfulProject.video.client.name}</span>
      </h2>
    ) : (
      <h2>
        {data.contentfulProject.video.client.name}
        <span> | {data.contentfulProject.video.title}</span>
      </h2>
    );

  return (
    <Layout
      location={location}
      isHealthProject={isHealthProject}
      isProject={isProject}
    >
      <Helmet>
        <title>{`${data.contentfulProject.client.name} | ${data.site.siteMetadata.title}`}</title>
        <meta
          name="description"
          content={data.contentfulProject?.description?.description}
        />
      </Helmet>
      <Wrapper>
        {hasPrimaryGif && (
          <div style={{ marginBottom: '25px' }}>
            <Image
              src={data.contentfulProject?.video?.gif?.file?.url}
              alt="test"
              gif="true"
            />
          </div>
        )}
        {!hasPrimaryGif && data.contentfulProject.video && (
          <Vimeo
            id={data.contentfulProject.video.vimeoId}
            title={data.contentfulProject.video.title}
            client={data.contentfulProject.video.client.name}
            gallery={false}
            portrait={isPortraitVideo}
          />
        )}
        {data.contentfulProject.video && (
          <Description>
            <Title>{projectTitle()}</Title>
            {data.contentfulProject.text && (
              <RichText document={data.contentfulProject.text.json} />
            )}
          </Description>
        )}
        {data.contentfulProject.additionalVideos &&
          data.contentfulProject.additionalVideos.map(item =>
            renderSwitch(item)
          )}
        <Pagination prev={prev} next={next} />
      </Wrapper>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        url
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      title
      client {
        name
      }
      video {
        vimeoId
        title
        client {
          name
        }
        gif {
          file {
            url
            contentType
          }
        }
        portrait
      }
      text {
        json
      }
      description {
        description
      }
      healthProject
      internal {
        type
      }
      additionalVideos {
        ... on ContentfulGalleryItem {
          id
          video {
            vimeoId
          }
          image {
            file {
              url
              contentType
            }
          }
        }
        ... on ContentfulVideo {
          id
          vimeoId
          client {
            name
          }
          title
          portrait
        }
        ... on ContentfulText {
          id
          richText {
            json
          }
          title
        }
        ... on ContentfulGrid {
          id
          items {
            id
            image {
              file {
                url
                contentType
              }
            }
            video {
              vimeoId
            }
          }
        }
      }
    }
  }
`;
