import React from 'react';
import styled from 'styled-components';
import Thumbnail from '@components/thumbnail';
import GalleryItem from '@components/galleryitem';

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  section {
    padding-right: 5px;

    &:nth-child(even) {
      padding-right: 0;
      padding-right: ${props => (props.columns === 3 ? '5px' : '0')};
    }

    &:last-child {
      padding-right: 0;
    }

    @media (max-width: 600px) {
      padding-right: 0;
    }
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

export default function Grid({ items }) {
  const getColumns = () => {
    if (items.length == 4 || items.length == 2) {
      return 2;
    }
    if (items.length == 3) {
      return 3;
    }
    return 1;
  };
  return (
    <Container columns={getColumns()}>
      {items.map(item => (
        <GalleryItem
          key={item.id}
          {...item}
          width={2000}
          columns={getColumns()}
        />
      ))}
    </Container>
  );
}
